import React, { useState, useEffect } from "react";
import {Steps, Divider, Button, Input, Alert, Radio, notification} from 'antd';
import {PlayCircleOutlined} from '@ant-design/icons';
import 'antd/dist/antd.css';
import SecureLS from "secure-ls";
import Api from "../common/Api";
import useCheckMobileScreen from "../useCheckMobileScreen";

const FinishedProgressBar = (props) => {
    const ls = new SecureLS({encodingType: 'aes'});
    const [boslukDoldurmaSorular, setBoslukDoldurmaSorular] = useState([]);
    const [coktanSecmeliSorular, setCoktanSecmeliSorular] = useState([]);
    const [dikteCalismaSorular, setDikteCalismaSorular] = useState([]);
    const [mixedSentenceSorular, setMixedSentenceSorular] = useState([]);
    const [bulmacaSorular, setBulmacaSorular] = useState();
    const [ceviriSorular, setCeviriSorular] = useState([]);
    useEffect(() => {
        const boslukDoldurmaSorular = []

        const dikteCalismaSorular = []

        const mixedSentenceSorular = []
        
        // When users answer and real answer has to check in Turkish Chars and TurkishLowerCase Use that command
        // item.kullaniciYaniti.toLocaleLowerCase('tr-TR').normalize('NFD').replace(/[\u0300-\u036f]/g, "")
        
        
        props.userExerciseAnswers.find((item) => {
            if(item.question_type === 'fill_in_the_blank_questions'){
                props.fillInTheBlankQuestions.find((fillInTheBlankQuestion) => {
                    if(parseInt(item.question_type_id) === fillInTheBlankQuestion.id){
                        let pushFillInTheBlankQuestionData = {
                            soru: fillInTheBlankQuestion.question,
                            cevap: fillInTheBlankQuestion.answer,
                            kullaniciYaniti: item.user_answer,
                            why_true: fillInTheBlankQuestion.why_true
                        }
                        boslukDoldurmaSorular.push(pushFillInTheBlankQuestionData)
                    }
                })
            }
            else if(item.question_type === 'dictation_questions'){
                props.dictationQuestions.find((dictationQuestion) => {
                    if(parseInt(item.question_type_id) === dictationQuestion.id){
                        let pushDictationQuestionData = {
                            musicPath: dictationQuestion.music_path,
                            cevap: dictationQuestion.answer,
                            kullaniciYaniti: item.user_answer
                        }
                        dikteCalismaSorular.push(pushDictationQuestionData)
                    }
                })
            }
            else if(item.question_type === 'mixed_sentence_questions'){
                props.mixedSentenceQuestions.find((mixedSentenceQuestion) => {
                    if(parseInt(item.question_type_id) === mixedSentenceQuestion.id){
                        let pushMixedSentenceQuestionData = {
                            soru: mixedSentenceQuestion.question,
                            soru_sonu: mixedSentenceQuestion.question_endmark,
                            cevap: mixedSentenceQuestion.answer,
                            kullaniciYaniti: item.user_answer
                        }
                        mixedSentenceSorular.push(pushMixedSentenceQuestionData)
                    }
                })
            }
            else if(item.question_type === 'crossword_questions'){
                let pushCrosswordQuestionData = {
                    trueCrosswordHTML: item.user_answer.trueCrosswordHTML,
                    emptyCrosswordData: item.user_answer.emptyCrosswordData,
                    userCrosswordData: item.user_answer.userCrosswordData,
                    isCrosswordTrue: item.user_answer.isCrosswordTrue
                }
                setBulmacaSorular(pushCrosswordQuestionData)
            }
        })
        
        let promises = []
        props.userExerciseAnswers.filter(item => item.question_type === "multiple_choice_questions")
            .map(item => {
            props.multipleChoiceQuestions.find((multipleChoiceQuestion) => {
                if(parseInt(item.question_type_id) === multipleChoiceQuestion.id){
                    promises.push(
                        Api.getQuestionById({
                            userId: ls.get('secret_key').userId,
                            questionId: multipleChoiceQuestion.id,
                            questionType: 'multipleChoiceQuestions'
                        })
                    )
                }
            })
        })
        props.userExerciseAnswers.filter(item => item.question_type === "translate_questions")
            .map(item => {
                props.translateQuestions.find((translateQuestion) => {
                    if(parseInt(item.question_type_id) === translateQuestion.id){
                        promises.push(
                            Api.getQuestionById({
                                userId: ls.get('secret_key').userId,
                                questionId: translateQuestion.id,
                                questionType: 'translateQuestions',
                            })
                        )
                    }
                })
            })
        
        let geciciCeviriSorular = [];
        let geciciCoktanSecmeliSorular = [];
        Promise
            .all(promises)
            .then(datas => {
                datas.forEach((dataFromPromise) => {
                    if(dataFromPromise.data.question_type === 'translateQuestions'){
                        let siklar = [];
                        dataFromPromise.data.options.forEach((option) => {
                            let sik = {
                                sikIcerik: option.option_content,
                                sikNo: option.option_no
                            }
                            siklar.push(sik)
                        })
                        let item = props.userExerciseAnswers.find(item => item.question_type === "translate_questions" && parseInt(item.question_type_id) === dataFromPromise.data.question.id)
                        let pushCeviriQuestionData = {
                            soruId: dataFromPromise.data.question.id,
                            userId: ls.get('secret_key').userId,
                            siklar: siklar,
                            soru: dataFromPromise.data.question.question,
                            cevap: parseInt(dataFromPromise.data.question.answer_no),
                            kullaniciYaniti: parseInt(item.user_answer)
                        }
                        geciciCeviriSorular.push(pushCeviriQuestionData)
                    }
                    else{
                        let siklar = [];
                        dataFromPromise.data.options.forEach((option) => {
                            let sik = {
                                sikIcerik: option.option_content,
                                sikNo: option.option_no
                            }
                            siklar.push(sik)
                        })
                        let item = props.userExerciseAnswers.find(item => item.question_type === "multiple_choice_questions" && parseInt(item.question_type_id) === dataFromPromise.data.question.id)
                        let pushMulitpleChoiceQuestionData = {
                            soruId: dataFromPromise.data.question.id,
                            userId: ls.get('secret_key').userId,
                            siklar: siklar,
                            soru: dataFromPromise.data.question.question,
                            cevap: parseInt(dataFromPromise.data.question.answer_no),
                            kullaniciYaniti: parseInt(item.user_answer),
                            why_true: dataFromPromise.data.question.why_true
                        }
                        geciciCoktanSecmeliSorular.push(pushMulitpleChoiceQuestionData)
                    }
                })
                setCeviriSorular(geciciCeviriSorular)
                setCoktanSecmeliSorular(geciciCoktanSecmeliSorular)
            }).catch(error => {
            console.log(error)
        })
        
         
        setBoslukDoldurmaSorular(boslukDoldurmaSorular)
        setDikteCalismaSorular(dikteCalismaSorular)
        setMixedSentenceSorular(mixedSentenceSorular)
        
    }, [props.userExerciseAnswers])

    const { Step } = Steps;
    
    const dogruSikYazdirmaCoktanSecmeli = (soruId, userAnswer, cevap) => {
        let ilgiliSorununSiklari = coktanSecmeliSorular.find((soru) => soru.soruId === soruId).siklar
        return ilgiliSorununSiklari.find(dogruSik => parseInt(dogruSik.sikNo) === parseInt(cevap)).sikIcerik;
    }

    const dogruSikYazdirmaCeviri = (soruId, userAnswer, cevap) => {
        let ilgiliSorununSiklari = ceviriSorular.find((soru) => soru.soruId === soruId).siklar
        return ilgiliSorununSiklari.find(dogruSik => parseInt(dogruSik.sikNo) === parseInt(cevap)).sikIcerik;
    }

    const createTrueCrossword = () => {
        if(bulmacaSorular !== undefined && bulmacaSorular !== null){
            return {__html: bulmacaSorular.trueCrosswordHTML}
        }
    }
    
    const createEmptyCrossword = () => {
        if(bulmacaSorular !== undefined && bulmacaSorular !== null){
            return {__html: bulmacaSorular.emptyCrosswordData}
        }
    }
    
    const putTwoCrossword = () => {
        return (
            <>
                {bulmacaSorular && bulmacaSorular.isCrosswordTrue === 1 
                    ? <Alert message="Doğru cevapladınız, tebrikler! (Eğer görünümde sıkıntı yaşıyorsanız lütfen Tarayıcı Ayarlarınızdan 'Masaüstü Görünüm' seçeneğine geçiş yapınız.)" type="success" /> 
                    : <Alert message="Hatalı cevapladığınız kısımlar var. Sonuçlarınızı kontrol ediniz! (Eğer görünümde sıkıntı yaşıyorsanız lütfen Tarayıcı Ayarlarınızdan 'Masaüstü Görünüm' seçeneğine geçiş yapınız.)" type="error" />}
                <Divider/>
                <div className="flex flex-wrap justify-center">
                    <div className="w-full lg:w-12/12 flex justify-center">
                        <div className="relative">
                            <h1 style={{textAlign: 'center'}}>Doğru Bulmaca Cevapları</h1>
                            <div dangerouslySetInnerHTML={createTrueCrossword()}></div>
                        </div>
                    </div>
                    <Divider/>
                    <div className="w-full lg:w-12/12 flex justify-center">
                        <div className="relative">
                            <h1 style={{textAlign: 'center'}}>Sizin Cevaplarınız</h1>
                            <div dangerouslySetInnerHTML={createEmptyCrossword()}></div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    
    useEffect(() => {
        if(bulmacaSorular && bulmacaSorular.userCrosswordData !== undefined){
            bulmacaSorular && bulmacaSorular.userCrosswordData.forEach((item) => {
                document.getElementById('crossword[' + item.row + '][' + item.col + ']').value = item.value
                document.getElementById('crossword[' + item.row + '][' + item.col + ']').disabled = true
            })
        }
    }, [putTwoCrossword()])

    const activeSteps = (props) => {
        if(props.exerciseType === 'mixed'){
            return (
                <Steps progressDot current={0} direction="vertical">
                    <Step
                        title="Boşluk Doldurma Soruları"
                        subTitle="Lütfen parantez içinde verdiğimiz kurala göre kelimenin doğru çekimini boşluğa yazınız."
                        description={
                            boslukDoldurmaSorular.map((item)=> (
                                <>
                                    {item.soru.split("...")[0]} <Input disabled defaultValue={item.kullaniciYaniti} /> {item.soru.split("...")[1]}
                                    {/*<Input addonBefore={item.soru.split("...")[0]} addonAfter={item.soru.split("...")[1]} disabled defaultValue={item.kullaniciYaniti} />*/}
                                    {item.kullaniciYaniti.toLocaleLowerCase('tr-TR') === item.cevap.replaceAll('I', 'i').toLocaleLowerCase('tr-TR') ?
                                        <Alert message="Doğru cevapladınız, tebrikler!" type="success" /> :
                                        <Alert message={"Yanlış cevapladınız! Doğru Cevap: " + item.cevap + " Neden Doğru? " + item.why_true} type="error" />}
                                </>
                            ))}
                    />
                    <Step
                        status="process"
                        title="Dikte Çalışması Soruları"
                        subTitle="Dinlediğiniz ses kaydında söylediklerimizi boşluğa yazınız."
                        description={
                            dikteCalismaSorular.map((item)=> (
                                <>
                                    <PlayCircleOutlined
                                        onClick={() => new Audio(item.musicPath).play()}
                                    /> <Input disabled
                                              defaultValue={item.kullaniciYaniti} />
                                    {/*<Input*/}
                                    {/*    addonBefore={*/}
                                    {/*        <PlayCircleOutlined*/}
                                    {/*            onClick={() => new Audio(item.musicPath).play()}*/}
                                    {/*        />*/}
                                    {/*    }*/}
                                    {/*    disabled*/}
                                    {/*    defaultValue={item.kullaniciYaniti}*/}
                                    {/*/>*/}
                                    {item.kullaniciYaniti.toLocaleLowerCase('tr-TR') === item.cevap.replaceAll('I', 'i').toLocaleLowerCase('tr-TR') ?
                                        <Alert message="Doğru cevapladınız, tebrikler!" type="success" /> :
                                        <Alert message={"Yanlış cevapladınız! Doğru Cevap: " + item.cevap} type="error" />}
                                </>
                            ))}
                    />
                    <Step
                        status="process"
                        title="Çoktan Seçmeli Sorular"
                        subTitle="Doğru olan şıkkı seçiniz."
                        description={
                            coktanSecmeliSorular.map((item)=> (
                                <>
                                    {item.soru}
                                    <br/>
                                    <Radio.Group disabled id={item.soruId} value={item.kullaniciYaniti}>
                                        {
                                            item.siklar.map((sik) => (
                                                <Radio key={parseInt(sik.sikNo)} value={parseInt(sik.sikNo)}>{sik.sikIcerik}</Radio>
                                            ))
                                        }
                                    </Radio.Group>
                                    {item.kullaniciYaniti === item.cevap ?
                                        <Alert message="Doğru cevapladınız, tebrikler!" type="success" /> :
                                        <Alert message={"Yanlış cevapladınız! Doğru Cevap: " + dogruSikYazdirmaCoktanSecmeli(item.soruId, item.kullaniciYaniti, item.cevap) + " Neden Doğru? " + item.why_true} type="error" />
                                    }
                                </>
                            ))}
                    />
                    <Step
                        status="process"
                        title="Türkçe Fransızca Çeviri Soruları"
                        subTitle="Sol tarafta yazdığımız Fransızca içeriği Türkçe'ye çeviriniz..."
                        description={
                            ceviriSorular.map((item)=> (
                                <>
                                    {item.soru}
                                    <br/>
                                    <Radio.Group disabled id={item.soruId} value={item.kullaniciYaniti}>
                                        {
                                            item.siklar.map((sik) => (
                                                <Radio key={parseInt(sik.sikNo)} value={parseInt(sik.sikNo)}>{sik.sikIcerik}</Radio>
                                            ))
                                        }
                                    </Radio.Group>
                                    {item.kullaniciYaniti === item.cevap ?
                                        <Alert message="Doğru cevapladınız, tebrikler!" type="success" /> :
                                        <Alert message={"Yanlış cevapladınız! Doğru Cevap: " + dogruSikYazdirmaCeviri(item.soruId, item.kullaniciYaniti, item.cevap)} type="error" />
                                    }
                                </>
                            ))}
                    />
                    <Step
                        status="process"
                        title="Düzgün Cümle Soruları"
                        subTitle="Lütfen verdiğimiz karmaşık cümleyi düzelterek tekrar yazınız."
                        description={
                            mixedSentenceSorular.map((item)=> (
                                <>
                                    {item.soru} <Input disabled defaultValue={item.kullaniciYaniti} />
                                    {/*<Input addonBefore={item.soru} addonAfter={item.soru_sonu} disabled defaultValue={item.kullaniciYaniti} />*/}
                                    {item.kullaniciYaniti.toLocaleLowerCase('tr-TR') === item.cevap.replaceAll('I', 'i').toLocaleLowerCase('tr-TR') ?
                                        <Alert message="Doğru cevapladınız, tebrikler!" type="success" /> :
                                        <Alert message={"Yanlış cevapladınız! Doğru Cevap: " + item.cevap } type="error" />}
                                </>
                            ))}
                    />
                </Steps>
            )
        }
        else if(props.exerciseType === 'crossword'){
            return (
                <Steps progressDot current={0} direction="vertical">
                    <Step
                        status="process"
                        title="Bulmaca Soruları"
                        subTitle="Lütfen sorduğumuz sorulara karşılık gelen yerleri doldurunuz."
                        description={putTwoCrossword()}
                    />
                </Steps>
            )
        }
        else if(props.exerciseType === 'single'){
            if(props.dictationQuestions.length !== 0){
                return (
                    <Steps progressDot current={0} direction="vertical">
                        <Step
                            status="process"
                            title="Dikte Çalışması Soruları"
                            subTitle="Dinlediğiniz ses kaydında söylediklerimizi boşluğa yazınız."
                            description={
                                dikteCalismaSorular.map((item)=> (
                                    <>
                                        <PlayCircleOutlined
                                            onClick={() => new Audio(item.musicPath).play()}
                                        /> <Input disabled
                                                  defaultValue={item.kullaniciYaniti} />
                                        {/*<Input*/}
                                        {/*    addonBefore={*/}
                                        {/*        <PlayCircleOutlined*/}
                                        {/*            onClick={() => new Audio(item.musicPath).play()}*/}
                                        {/*        />*/}
                                        {/*    }*/}
                                        {/*    disabled*/}
                                        {/*    defaultValue={item.kullaniciYaniti}*/}
                                        {/*/>*/}
                                        {item.kullaniciYaniti.toLocaleLowerCase('tr-TR') === item.cevap.replace(/I/g, 'i').toLocaleLowerCase('tr-TR') ?
                                            <Alert message="Doğru cevapladınız, tebrikler!" type="success" /> :
                                            <Alert message={"Yanlış cevapladınız! | Doğru Cevap: " + item.cevap} type="error" />}
                                        <br/>
                                    </>
                                ))}
                        />
                    </Steps>
                )
            }
            else if(props.fillInTheBlankQuestions.length !== 0){
                return (
                    <Steps progressDot current={0} direction="vertical">
                        <Step
                            title="Boşluk Doldurma Soruları"
                            subTitle="Lütfen parantez içinde verdiğimiz kurala göre kelimenin doğru çekimini boşluğa yazınız."
                            description={
                                boslukDoldurmaSorular.map((item)=> (
                                    <>
                                        {item.soru.split("...")[0]} <Input defaultValue={item.kullaniciYaniti} disabled={true}/> {item.soru.split("...")[1]}
                                        {/*<Input addonBefore={item.soru.split("...")[0]} addonAfter={item.soru.split("...")[1]} disabled defaultValue={item.kullaniciYaniti} />*/}
                                        {item.kullaniciYaniti.toLocaleLowerCase('tr-TR') === item.cevap.replace(/I/g, 'i').toLocaleLowerCase('tr-TR') ?
                                            <Alert message="Doğru cevapladınız, tebrikler!" type="success" /> :
                                            <Alert message={"Yanlış cevapladınız! | Doğru Cevap: " + item.cevap + " | Neden Doğru? " + item.why_true} type="error" />}
                                            <br/>
                                    </>
                                ))}
                        />
                    </Steps>
                )
            }
            else if(props.mixedSentenceQuestions.length !== 0){
                return (
                    <Steps progressDot current={0} direction="vertical">
                        <Step
                            status="process"
                            title="Düzgün Cümle Soruları"
                            subTitle="Lütfen verdiğimiz karmaşık cümleyi düzelterek tekrar yazınız."
                            description={
                                mixedSentenceSorular.map((item)=> (
                                    <>
                                        {item.soru} <Input disabled defaultValue={item.kullaniciYaniti} />
                                        {/*<Input addonBefore={item.soru} addonAfter={item.soru_sonu} disabled defaultValue={item.kullaniciYaniti} />*/}
                                        {item.kullaniciYaniti.toLocaleLowerCase('tr-TR') === item.cevap.replace(/I/g, 'i').toLocaleLowerCase('tr-TR') ?
                                            <Alert message="Doğru cevapladınız, tebrikler!" type="success" /> :
                                            <Alert message={"Yanlış cevapladınız! | Doğru Cevap: " + item.cevap } type="error" />}
                                        <br/>
                                    </>
                                ))}
                        />
                    </Steps>
                )
            }
            else if(props.multipleChoiceQuestions.length !== 0){
                return (
                    <Steps progressDot current={0} direction="vertical">
                        <Step
                            status="process"
                            title="Çoktan Seçmeli Sorular"
                            subTitle="Doğru olan şıkkı seçiniz."
                            description={
                                coktanSecmeliSorular.map((item)=> (
                                    <>
                                        {item.soru}
                                        <br/>
                                        <Radio.Group disabled id={item.soruId} value={item.kullaniciYaniti}>
                                            {
                                                item.siklar.map((sik) => (
                                                    <Radio key={parseInt(sik.sikNo)} value={parseInt(sik.sikNo)}>{sik.sikIcerik}</Radio>
                                                ))
                                            }
                                        </Radio.Group>
                                        {item.kullaniciYaniti === item.cevap ?
                                            <Alert message="Doğru cevapladınız, tebrikler!" type="success" /> :
                                            <Alert message={"Yanlış cevapladınız! | Doğru Cevap: " + dogruSikYazdirmaCoktanSecmeli(item.soruId, item.kullaniciYaniti, item.cevap) + " | Neden Doğru? " + item.why_true} type="error" />
                                        }
                                        <br/>
                                    </>
                                ))}
                        />
                    </Steps>
                )
            }
            else if(props.translateQuestions.length !== 0){
                return (
                    <Steps progressDot current={0} direction="vertical">
                        <Step
                            status="process"
                            title="Türkçe Fransızca Çeviri Soruları"
                            subTitle="Doğru çeviriyi seçiniz..."
                            description={
                                ceviriSorular.map((item)=> (
                                    <>
                                        {item.soru}
                                        <br/>
                                        <Radio.Group disabled id={item.soruId} value={item.kullaniciYaniti}>
                                            {
                                                item.siklar.map((sik) => (
                                                    <Radio key={parseInt(sik.sikNo)} value={parseInt(sik.sikNo)}>{sik.sikIcerik}</Radio>
                                                ))
                                            }
                                        </Radio.Group>
                                        {item.kullaniciYaniti === item.cevap ?
                                            <Alert message="Doğru cevapladınız, tebrikler!" type="success" /> :
                                            <Alert message={"Yanlış cevapladınız! | Doğru Cevap: " + dogruSikYazdirmaCeviri(item.soruId, item.kullaniciYaniti, item.cevap)} type="error" />
                                        }
                                        <br/>
                                    </>
                                ))}
                        />
                    </Steps>
                )
            }
        }
    }
    return(
        <>
            <Steps progressDot 
                   direction={useCheckMobileScreen() === true ? 'vertical' : 'horizontal'} 
                   current={2}>
                <Step
                    title="Başlayın!"
                    description={
                        <Button type="primary" onClick={() => {
                            Api.restartUserExerciseBySlug({
                                exercise_slug: props.exerciseSlug,
                                userExerciseAnswers: props.userExerciseAnswers
                            })
                                .then((response) => {
                                    if(response.data.code === 1){
                                        notification.success({message: 'Başarılı!', description: 'Başarıyla egzersiz yeniden başlatıdı!'})
                                        window.location.reload();
                                    }
                                })
                                .catch(err => console.log(err.response.request))
                        }}>Tekrar Başla!</Button>
                    }
                />
                <Step
                    title="Başladınız..."
                    description="Lütfen soruları çözünüz."
                />
                <Step
                    title="Bitirin!"
                    description={<Button type="danger" disabled>Şimdi Bitir!</Button>}
                />
            </Steps>
            <Divider />
            {activeSteps(props)}
        </>
    )
}

export default FinishedProgressBar;