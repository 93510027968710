import React, {useEffect, useState} from "react";
import Navbar from "../components/Navbars/AuthNavbar.js";
import Footer from "../components/Footers/Footer.js";
import NotStartedProgressBar from "../components/ProgressBars/NotStartedProgressBar";
import StartedProgressBar from "../components/ProgressBars/StartedProgressBar";
import FinishedProgressBar from "../components/ProgressBars/FinishedProgressBar";
import {Button, Spin, Popconfirm, message, Rate, notification} from 'antd';
import SecureLS from "secure-ls";
import Api from "../components/common/Api";
import Profile from '../img/dilegzersizlogo.png';
import '../styles/index.css';
import '../styles/tailwind.css';
import {graphql, navigate} from 'gatsby';
import SEO from "../components/seo";

const EgzersizSayfasi = () => {
  let exerciseSlug = '';
  if (typeof localStorage !== 'undefined') {
    if(window.location.pathname.split('/')[2] === 'egzersiz'){
      exerciseSlug = window.location.pathname.split('/')[3]
    }else if(window.location.pathname.split('/')[1] === 'egzersiz'){
      exerciseSlug = window.location.pathname.split('/')[2]
    }
  }
  let userRole = '';
  let userId = 0
  if (typeof localStorage !== 'undefined') {
    const ls = new SecureLS({encodingType: 'aes'});
    userRole = ls.get('secret_key').userRole
    userId = ls.get('secret_key').userId
  }
  
  const [dictationQuestions, setDictationQuestions] = useState([]);
  const [fillInTheBlankQuestions, setFillInTheBlankQuestions] = useState([]);
  const [multipleChoiceQuestions, setMultipleChoiceQuestions] = useState([]);
  const [translateQuestions, setTranslateQuestions] = useState([]);
  const [mixedSentenceQuestions, setMixedSentenceQuestions] = useState([]);
  const [userExerciseAnswers, setUserExerciseAnswers] = useState([]);
  const [crosswordArray, setCrosswordArray] = useState('');
  const [isMaking, setIsMaking] = useState(2);
  const [isStarted, setIsStarted] = useState(0);
  const [isFinished, setIsFinished] = useState(0);
  const [exerciseRateCount, setExerciseRateCount] = useState(0);
  const [exerciseRate, setExerciseRate] = useState(0);
  const [exerciseResult, setExerciseResult] = useState();
  const [exerciseId, setExerciseId] = useState(0);
  const [exerciseName, setExerciseName] = useState('');
  const [exerciseType, setExerciseType] = useState();
  const [exerciseDescription, setExerciseDescription] = useState('');
  const [anlikRate, setAnlikRate] = useState();
  const [isExerciseImportant, setIsExerciseImportant] = useState();
  
  let isGuest = 0;
  
  if(userRole === 'guest_customer'){
    isGuest = 1;
  }
  const loadData = () => {
    Api.getExerciseBySlug({
      userId: userId,
      isGuest: isGuest,
      exerciseSlug: exerciseSlug
    }).then((response) => {
          if(response.data.code === 1){
            setIsExerciseImportant(response.data.isExerciseImportant)
            setDictationQuestions(response.data.dictationQuestions)
            setFillInTheBlankQuestions(response.data.fillInTheBlankQuestions)
            setMultipleChoiceQuestions(response.data.multipleChoiceQuestions)
            setTranslateQuestions(response.data.translateQuestions)
            setMixedSentenceQuestions(response.data.mixedSentenceQuestions)
            setIsMaking(response.data.exerciseStateIsMaking)
            setIsFinished(response.data.exerciseStateIsFinished)
            setIsStarted(response.data.exerciseStateIsStarted)
            setExerciseRateCount(response.data.exerciseRateCount)
            setExerciseResult(response.data.exerciseResult)
            setExerciseId(response.data.exerciseId)
            setExerciseName(response.data.exerciseName)
            setExerciseType(response.data.exerciseType)
            setExerciseRate(response.data.exercise_rate)
            setUserExerciseAnswers(response.data.userExerciseAnswers)
            setExerciseDescription(response.data.exerciseDescription)
            let setEdilecekData = {
              crosswordArray: response.data.crosswordArray,
              crosswordArrayDolu: response.data.crosswordArrayDolu,
              crosswordTrueArray: response.data.crosswordTrueArray,
              exerciseId: response.data.exerciseId
            }
            setCrosswordArray(setEdilecekData)
          }
          else if(response.data.code === 0){
            message.error(response.data.message)
            setTimeout(() => {
              navigate("/")
            }, 2000)
          }
        })
        .catch((err) => console.log(err));
  }
  
  useEffect(() => {
    loadData();
  }, [])

  function confirmOyVer(e) {
    Api.saveRateForExercise({
      userId: userId,
      exerciseId: exerciseId,
      exerciseRate: anlikRate
    }).then((response) => {
      if(response.data.code === 1){
        setExerciseRate(anlikRate)
        message.success('Başarıyla oy verdiniz!');
        loadData();
      }
    }).catch((err) => console.log(err.response.request));
  }

  function cancelOyVer(e) {
    message.error('Oy vermekten vazgeçtiniz!');
  }
  
  return (
    <>
      <SEO
          title={exerciseName}
          description={exerciseDescription}
      />
      <Navbar transparent />
      <main className="profile-page">
        <section className="relative block h-500-px">
          <div
              className="absolute top-0 w-full h-full bg-center bg-cover"
              style={{
                backgroundImage:
                    "url('https://images.unsplash.com/photo-1499336315816-097655dcfbda?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2710&q=80')",
              }}
          >
            <span
                id="blackOverlay"
                className="w-full h-full absolute opacity-50 bg-black"
            >
              
            </span>
          </div>
          <div
              className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
              style={{ transform: "translateZ(0)" }}
          >
            <svg
                className="absolute bottom-0 overflow-hidden"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
            >
              <polygon
                  className="text-gray-300 fill-current"
                  points="2560 0 2560 100 0 100"
              >
                
              </polygon>
            </svg>
          </div>
          <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                <div className="mt-24">
                  <h1 className="text-white font-semibold text-2xl">
                    {exerciseName}
                  </h1>
                  <p className="text-white font-semibold">
                    <Button
                        type="primary"
                        style={{display: "none"}}
                        onClick={() => {
                          Api.getNextExercise({id: exerciseId, nowExerciseSlug: exerciseSlug}).then((response) => {
                            if(response.data.code === 1){
                              if (typeof localStorage !== 'undefined'){
                                const win = window.open('https://fransizcaogreniyorum.net/egzersizler/egzersiz/' + response.data.data['exercise_slug'], '_blank');
                                win.focus();
                              }
                            }else{
                              notification.error({message: 'Başarısız!', description: response.data.message})
                            }
                          })
                        }}
                    >
                      Sonraki Egzersize Geç!
                    </Button>
                    <br/>
                    {exerciseDescription}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="relative py-16 bg-gray-300">
          <div className="container mx-auto px-4">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
              <div className="px-6">
                <div className="flex flex-wrap justify-center">
                  <div className="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">
                    <div className="relative">
                      <img
                        alt="fransizca ogreniyorum kullanici resmi"
                        src={Profile}
                        className="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px"
                      />
                    </div>
                  </div>
                  <div className="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center">
                    <div className="py-6 px-3 mt-10 sm:mt-0">
                      {isFinished === 1 && exerciseResult !== '1'
                          ? <div className="lg:mr-4 p-3 text-center">
                            <span className="text-5xl font-bold block uppercase tracking-wide text-gray-700">
                              {exerciseResult}
                            </span>
                            <span className="text-xl text-gray-700">Puan Aldınız</span>
                          </div>
                          : <div className="lg:mr-4 p-3 text-center">
                            <span className="text-xl font-bold block uppercase tracking-wide text-gray-700">
                              
                            </span>
                            <span className="text-sm text-gray-500"> </span>
                          </div>
                      }
                    {/*  {isFinished === 1 && userRole !== 'guest_customer'*/}
                    {/*      ? <Popconfirm*/}
                    {/*          title={<Rate defaultValue={exerciseRate} onChange={(value) => {setAnlikRate(value)}} />}*/}
                    {/*          onConfirm={confirmOyVer}*/}
                    {/*          onCancel={cancelOyVer}*/}
                    {/*          okText="Oy Ver"*/}
                    {/*          icon={false}*/}
                    {/*          cancelText="Vazgeç"*/}
                    {/*      >*/}
                    {/*        <Button type="primary">ŞİMDİ OY VER!</Button>*/}
                    {/*      </Popconfirm>*/}
                    {/*      : <Button type="primary" disabled>Üyeyseniz Oy Vermek İçin Lütfen Egzersizi Bitiriniz!</Button>*/}
                    {/*  }*/}
                    </div>
                  </div>
                  <div className="w-full lg:w-4/12 px-4 lg:order-1">
                    <div className="flex justify-center py-4 lg:pt-4 pt-8">
                      <div className="mr-4 p-3 text-center">
                        <span className="text-xl font-bold block uppercase tracking-wide text-gray-700">
                          {exerciseType && exerciseType === 'mixed' || exerciseType === 'single' ? 10 : 1}
                        </span>
                        <span className="text-sm text-gray-500">Soru</span>
                      </div>
                      <div className="mr-4 p-3 text-center">
                        {/*<span className="text-xl font-bold block uppercase tracking-wide text-gray-700">*/}
                        {/*  {exerciseRateCount}*/}
                        {/*</span>*/}
                        {/*<span className="text-sm text-gray-500">Kez Oylandı</span>*/}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center mt-12">
                  {isMaking === 2 ? <Spin /> : ''}
                  {isFinished === 0 && isMaking === 0 && isStarted === 0 ? 
                      <NotStartedProgressBar 
                          exerciseId={exerciseId} 
                          userId={userId}
                          isDone={(isReload) => {
                            isReload && loadData();
                            setIsStarted(1);
                            setIsMaking(1);
                          }}
                      /> : ''}
                  {isMaking === 1 ? <StartedProgressBar 
                      dictationQuestions={dictationQuestions}
                      translateQuestions={translateQuestions}
                      mixedSentenceQuestions={mixedSentenceQuestions}
                      multipleChoiceQuestions={multipleChoiceQuestions}
                      fillInTheBlankQuestions={fillInTheBlankQuestions}
                      userExerciseAnswers={userExerciseAnswers}
                      crosswordArray={crosswordArray}
                      exerciseSlug={exerciseSlug}
                      isDone={(isReload) => {
                        isReload && loadData();
                        setIsStarted(0);
                        setIsMaking(0);
                        setIsFinished(1);
                      }}
                      exerciseCount={exerciseType === 'single' || exerciseType === 'mixed' ? 10 : 1} 
                  /*    Burada +1 eklendi çünkü crossword question her egzersizde 1 tane olmalı*/
                  /> : ''}
                  {isFinished === 1 ? <FinishedProgressBar
                      dictationQuestions={dictationQuestions}
                      translateQuestions={translateQuestions}
                      mixedSentenceQuestions={mixedSentenceQuestions}
                      multipleChoiceQuestions={multipleChoiceQuestions}
                      fillInTheBlankQuestions={fillInTheBlankQuestions}
                      exerciseType={exerciseType}
                      exerciseSlug={exerciseSlug}
                      exerciseCount={exerciseType === 'single' ? 10 : 1}
                      userExerciseAnswers={userExerciseAnswers}
                  /> : ''}
                </div>
                <div className="mt-10 py-10 border-t border-gray-300 text-center">
                  <div className="flex flex-wrap justify-center">
                    <div className="w-full lg:w-9/12 px-4">
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
export default EgzersizSayfasi;
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`