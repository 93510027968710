import React, { useState } from "react";
import {Modal, Button, Radio, Form, notification} from 'antd';
import 'antd/dist/antd.css';
import SecureLS from "secure-ls";
import Api from "../common/Api";

function CeviriModal (props) {
    const ls = new SecureLS({encodingType: 'aes'});
    const {ceviriModal, visibleState} = props;
    const [valueCeviri, setValueCeviri] = useState(null)

    const onFinish = values => {
        console.log('form: ',values)
        
        //burada gelen datayı direkt olarak user_exercise_answers tablosunda yeni bir rowa kaydet
        //egzersiz sayfasında butonu kırmızı yapabilirsin yapıldı anlamında
        //hemen sonrasında kapatıcaz modalı onaylandı manasında

        const openSuccessChangeAnswerNotificationWithIcon = type => {
            notification[type]({
                message: 'Cevabınız Başarıyla Kaydedildi!',
                description:
                    'Tebrikler, cevabınız başarıyla kaydedildi. Diğer soruya geçebilirsiniz!',
            });
        };
        
        Api.saveAnswerToUserExerciseAnswers({
            optionNo: values.option,
            userId: ls.get('secret_key').userId,
            exerciseId : ceviriModal.translateQuestion.exercise_id,
            questionId : ceviriModal.questionId,
            questionType : 'translate_questions'
        }).then((response) => {
            if(response.data.code === 1){
                openSuccessChangeAnswerNotificationWithIcon('success')
                visibleState(true)
            }
        }).catch((err) => console.log( err.response.request ));
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    const onChangeCeviri = e => {
        setValueCeviri(e.target.value)
    };
    
    return(
        <>
            <Modal
                title={ceviriModal && ceviriModal.translateQuestion.question}
                visible={!!ceviriModal}
                // onOk={onFinish}
                onCancel={props.visibleState}
                footer={null}
            >
                <Form
                    name="ceviriForm"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        name="option"
                    >
                        <Radio.Group
                            onChange={onChangeCeviri}
                            value={valueCeviri}
                        >
                            {
                                ceviriModal && ceviriModal.translateQuestionOptions.map((sik) => (
                                    <Radio value={sik.option_no}>{sik.option_content}</Radio>
                                ))
                            }
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Cevabı Kaydet!
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default CeviriModal;