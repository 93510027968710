import React, {Component} from "react";
import {Steps, Button, notification} from 'antd';
import 'antd/dist/antd.css';
import Api from "../common/Api";
import useCheckMobileScreen from "../useCheckMobileScreen";

class NotStartedProgressBar extends Component {
    constructor(props) {
        super(props);
        this.state = {loading: false};
    }
    render() {
        const { Step } = Steps;
        return(
            <>
                <Steps progressDot 
                       // direction={useCheckMobileScreen() === true ? 'vertical' : 'horizontal'} 
                       current={0}>
                    <Step 
                        title="Başlayın!" 
                        description={<Button
                            type="primary"
                            loading={this.state.loading}
                            onClick={() => {
                                this.setState({
                                    'loading': true
                                })
                                const openStartSuccessNotificationWithIcon = type => {
                                    notification[type]({
                                        message: 'Egzersiz Başarıyla Başlatıldı!',
                                        description:
                                            'Şimdi egzersizi yapmaya başlayabilirsiniz. Egzersiz bittiğinde lütfen "Şimdi Bitir" butonuna tıklayınız!',
                                    });
                                };
                                Api.changeUserExerciseState({
                                    userId: this.props.userId,
                                    exerciseId: this.props.exerciseId
                                })
                                    .then((response) => {
                                        if(response.data.code === 1){
                                            openStartSuccessNotificationWithIcon('success')
                                            setTimeout(() => {
                                                this.props.isDone(true)
                                                this.setState({
                                                    'loading': false
                                                })
                                            }, 3000)
                                        }
                                    })
                                    .catch((err) => console.log(err.response.request));
                            }}
                        >Şimdi Başla!</Button>} 
                    />
                    <Step 
                        title="Başladınız..."
                        description="Lütfen soruları çözünüz." 
                    />
                    <Step 
                        title="Bitirin!"
                        description={<Button type="danger" disabled>Şimdi Bitir!</Button>}
                    />
                </Steps>
            </>
        )
    }
}

export default NotStartedProgressBar;