import React, {useState, useEffect} from "react";
import {Steps, Divider, Button, Input, notification, Form, Popover} from 'antd';
import {PlayCircleOutlined} from '@ant-design/icons';
import 'antd/dist/antd.css';
import CoktanSecmeliModal from "../Modals/CoktanSecmeliModal";
import SecureLS from "secure-ls";
import CrosswordModal from "../Modals/CrosswordModal";
import Api from "../common/Api";
import CeviriModal from "../Modals/CeviriModal";
import useCheckMobileScreen from "../useCheckMobileScreen";

const StartedProgressBar = (props) => {
    const ls = new SecureLS({encodingType: 'aes'});
    const [coktanSecmeliModal, setCoktanSecmeliModal] = useState()
    const [ceviriModal, setCeviriModal] = useState()
    const [crosswordModal, setCrosswordModal] = useState()
    const [userExerciseAnswers, setUserExerciseAnswers] = useState([])
    const [exerciseType, setExerciseType] = useState('')
    const [input, setInput] = useState('');
    const [form] = Form.useForm();
    const { Step } = Steps;

    const loadProgressBarData = () => {
        setUserExerciseAnswers(null)
        Api.getExerciseBySlug({
            userId: ls.get('secret_key').userId,
            exerciseSlug: props.exerciseSlug
        })
        .then((response) => {
            if(response.data.code === 1){
                setUserExerciseAnswers(response.data.userExerciseAnswers)
                setExerciseType(response.data.exerciseType)
            }
        })
        .catch((err) => console.log(err));
    }

    useEffect(() => {
        loadProgressBarData();
    }, []);
    
    const harfEkle = (value, name) => {
        let anlikValue = form.getFieldValue(name);
        if(anlikValue === undefined){
            anlikValue = '';
        }
        form.setFieldsValue({[name]: anlikValue + value})
    }
    
    const harfler = (name) => {
        return (
            <>
                <Button onClick={() => harfEkle('ç', name)}>ç</Button>
                <Button onClick={() => harfEkle('ı', name)}>ı</Button>
                <Button onClick={() => harfEkle('ğ', name)}>ğ</Button>
                <Button onClick={() => harfEkle('ö', name)}>ö</Button>
                <Button onClick={() => harfEkle('ş', name)}>ş</Button>
                <Button onClick={() => harfEkle('ü', name)}>ü</Button>
                <Button onClick={() => harfEkle('â', name)}>â</Button>
                <Button onClick={() => harfEkle('î', name)}>î</Button>
                <Button onClick={() => harfEkle('û', name)}>û</Button>
                <Button onClick={() => harfEkle('à', name)}>à</Button>
                <Button onClick={() => harfEkle('á', name)}>á</Button>
                <Button onClick={() => harfEkle('ã', name)}>ã</Button>
                <Button onClick={() => harfEkle('ä', name)}>ä</Button>
                <Button onClick={() => harfEkle('è', name)}>è</Button>
                <Button onClick={() => harfEkle('ê', name)}>ê</Button>
                <Button onClick={() => harfEkle('é', name)}>é</Button>
                <Button onClick={() => harfEkle('ë', name)}>ë</Button>
                <Button onClick={() => harfEkle('ì', name)}>ì</Button>
                <Button onClick={() => harfEkle('í', name)}>í</Button>
                <Button onClick={() => harfEkle('ï', name)}>ï</Button>
                <Button onClick={() => harfEkle('ñ', name)}>ñ</Button>
                <Button onClick={() => harfEkle('ò', name)}>ò</Button>
                <Button onClick={() => harfEkle('ó', name)}>ó</Button>
                <Button onClick={() => harfEkle('õ', name)}>õ</Button>
                <Button onClick={() => harfEkle('ù', name)}>ù</Button>
                <Button onClick={() => harfEkle('ú', name)}>ú</Button>
            </>
        )
    }
    
    const activeSteps = (props) => {
        if(exerciseType === 'mixed'){
            return (
                <Steps progressDot current={0} direction="vertical">
                    <Step
                        status="process"
                        title="Dikte Çalışması Soruları"
                        subTitle="Dinlediğiniz ses kaydında söylediklerimizi boşluğa yazınız."
                        description={
                            props.dictationQuestions.map((item)=> (
                                <>
                                    <Popover content={harfler("dictationQuestion/" + item.id)} title="Harflere Tıklayın!" trigger="click">
                                        <Button>Fransızca/Türkçe Klavye</Button>
                                    </Popover>
                                    <Form.Item
                                        label={<PlayCircleOutlined
                                            onClick={() => new Audio(item.music_path).play()}
                                        />}
                                        extra={<><span style={{color: 'black'}}>{item.translation}</span></>}
                                        name={"dictationQuestion/" + item.id}
                                    >
                                        <Input />
                                        {/*<Input*/}
                                        {/*    addonBefore={*/}
                                        {/*        <PlayCircleOutlined*/}
                                        {/*            onClick={() => new Audio(item.music_path).play()}*/}
                                        {/*        />*/}
                                        {/*    }*/}
                                        {/*    defaultValue=""*/}
                                        {/*/>*/}
                                    </Form.Item>
                                </>
                            ))}
                    />
                    <Step
                        title="Boşluk Doldurma Soruları"
                        status="process"
                        subTitle="Lütfen parantez içinde verdiğimiz kurala göre kelimenin doğru çekimini boşluğa yazınız."
                        description={
                            props.fillInTheBlankQuestions.map((item)=> (
                                <>
                                    <Popover content={harfler("fillInTheBlankQuestion/" + item.id)} title="Harflere Tıklayın!" trigger="click">
                                        <Button>Fransızca/Türkçe Klavye</Button>
                                    </Popover>
                                    <Form.Item
                                        label={item.question.split("...")[0]}
                                        name={"fillInTheBlankQuestion/" + item.id}
                                        extra={<><span style={{color: 'black'}}>{item.question.split("...")[1]}</span></>}
                                    >
                                        <Input />
                                        {/*<Input addonBefore={item.question.split("...")[0]} addonAfter={item.question.split("...")[1]} defaultValue="" />*/}
                                    </Form.Item>
                                </>
                            ))}
                    />
                    <Step
                        status="process"
                        title="Düzgün Cümle Soruları"
                        subTitle="Lütfen verdiğimiz karmaşık cümleyi düzelterek tekrar yazınız."
                        description={
                            props.mixedSentenceQuestions.map((item)=> (
                                <>
                                    <Popover content={harfler("mixedSentenceQuestion/" + item.id)} title="Harflere Tıklayın!" trigger="click">
                                        <Button>Fransızca/Türkçe Klavye</Button>
                                    </Popover>
                                    <Form.Item
                                        label={item.question}
                                        name={"mixedSentenceQuestion/" + item.id}
                                    >
                                        <Input />
                                        {/*<Input addonBefore={item.question} defaultValue="" />*/}
                                    </Form.Item>
                                </>
                            ))}
                    />
                    <Step
                        status="process"
                        title="Çoktan Seçmeli Sorular"
                        subTitle="Doğru olan şıkkı seçiniz."
                        description={
                            props.multipleChoiceQuestions.map((item)=> (
                                <>
                                    <br/>
                                    {renderCoktanSecmeliSoruButton(item.id)}
                                    <br/>
                                </>
                            ))}
                    />
                    <Step
                        status="process"
                        title="Türkçe Fransızca Çeviri Soruları"
                        subTitle="Sol tarafta yazdığımız Fransızca içeriği Türkçe'ye çeviriniz..."
                        description={
                            props.translateQuestions.map((item)=> (
                                <>
                                    <br/>
                                    {renderCeviriSoruButton(item.id)}
                                    <br/>
                                </>
                            ))}
                    />
                </Steps>
            )
        }
        else if(exerciseType === 'crossword'){
            return (
                <Steps progressDot current={0} direction="vertical">
                    <Step
                        status="process"
                        title="Bulmaca Soruları"
                        subTitle="Lütfen sorduğumuz sorulara karşılık gelen yerleri doldurunuz."
                        description={renderBulmacaButton()}
                    />
                </Steps>
            )
        }
        else if(exerciseType === 'single'){
            if(props.dictationQuestions.length !== 0){
                return (
                    <Steps progressDot current={0} direction="vertical">
                        <Step
                            status="process"
                            title="Dikte Çalışması Soruları"
                            subTitle="Dinlediğiniz ses kaydında söylediklerimizi boşluğa yazınız."
                            description={
                                props.dictationQuestions.map((item, index)=> (
                                    <>
                                        <Form.Item
                                            label={(index+1) + '. Ses'}
                                            name={"dictationQuestion/" + item.id}
                                        >
                                            <Input
                                                style={{width: 'auto'}}
                                                addonBefore={
                                                    <PlayCircleOutlined
                                                        onClick={() => new Audio(item.music_path).play()}
                                                    />
                                                }
                                                addonAfter={<span style={{color: 'black'}}>{item.translation}</span>}
                                                defaultValue=""
                                            />
                                        </Form.Item>
                                    </>
                                ))}
                        />
                    </Steps>
                )
            }
            else if(props.fillInTheBlankQuestions.length !== 0){
                return (
                        <Steps progressDot current={0} direction="vertical">
                            <Step
                                title="Boşluk Doldurma Soruları"
                                subTitle="Lütfen parantez içinde verdiğimiz kurala göre kelimenin doğru çekimini boşluğa yazınız."
                                description={
                                    props.fillInTheBlankQuestions.map((item, index)=> (
                                        <>
                                            <Form.Item
                                                label={(index+1) + '. Soru'}
                                                name={"fillInTheBlankQuestion/" + item.id}
                                            >
                                                <Input style={{width: 'auto'}} addonBefore={item.question.replace('…', '...').split("...")[0]} addonAfter={<span style={{color: 'black'}}>{item.question.replace('…', '...').split("...")[1]}</span>} />
                                            </Form.Item>
                                        </>
                                    ))}
                            />
                        </Steps>
                    )
            }
            else if(props.mixedSentenceQuestions.length !== 0){
                return (
                    <Steps progressDot current={0} direction="vertical">
                        <Step
                            status="process"
                            title="Düzgün Cümle Soruları"
                            subTitle="Lütfen verdiğimiz karmaşık cümleyi düzelterek tekrar yazınız."
                            description={
                                props.mixedSentenceQuestions.map((item)=> (
                                    <>
                                        <Form.Item
                                            label={item.question}
                                            name={"mixedSentenceQuestion/" + item.id}
                                        >
                                            <Input />
                                            {/*<Input addonBefore={item.question} defaultValue="" />*/}
                                        </Form.Item>
                                    </>
                                ))}
                        />
                    </Steps>
                )
            }
            else if(props.multipleChoiceQuestions.length !== 0){
                return (
                    <Steps progressDot current={0} direction="vertical">
                        <Step
                            status="process"
                            title="Çoktan Seçmeli Sorular"
                            subTitle="Doğru olan şıkkı seçiniz."
                            description={
                                props.multipleChoiceQuestions.map((item, index)=> (
                                    <>
                                        <br/>
                                        {renderCoktanSecmeliSoruButton(item.id, index+1)}
                                        <br/>
                                    </>
                                ))}
                        />
                    </Steps>
                )
            }
            else if(props.translateQuestions.length !== 0){
                return (
                    <Steps progressDot current={0} direction="vertical">
                        <Step
                            status="process"
                            title="Türkçe Fransızca Çeviri Soruları"
                            subTitle="Doğru çeviriyi seçiniz..."
                            description={
                                props.translateQuestions.map((item, index)=> (
                                    <>
                                        <br/>
                                        {renderCeviriSoruButton(item.id, index+1)}
                                        <br/>
                                    </>
                                ))}
                        />
                    </Steps>
                )
            }
        }
    }

    const renderBulmacaButton = () => {
        let isAnswerExist = userExerciseAnswers && userExerciseAnswers.find(function (item) {
            return item.question_type === 'crossword_questions';
        })

        if (isAnswerExist) {
            return (
                <Button 
                    onClick={() => setCrosswordModal(props.crosswordArray)} 
                    style={{backgroundColor: "#52c41a", color: 'white'}} 
                    type="primary">Cevaplarınız Kaydedildi, Yeniden Başlamak İçin Tıklayın!</Button>
            )
        }
        else {
            return (
                <Button onClick={() => setCrosswordModal(props.crosswordArray)} type="primary">Bulmacayı Çözmek İçin Tıkla!</Button>
            )
        }
    }
    
    const renderCoktanSecmeliSoruButton = (questionId, index) => {
        let isAnswerExist = userExerciseAnswers && userExerciseAnswers.find(function (item) {
            return parseInt(item.question_type_id) === questionId && item.question_type === 'multiple_choice_questions';
        })
        
        if(isAnswerExist){
            return (
                <Button
                    type="dashed"
                    style={{backgroundColor: "#52c41a", color: 'white'}}
                    onClick={() => {
                        let userAnswer = userExerciseAnswers.find((item) => {
                            if(parseInt(item.question_type_id) === questionId) {
                                return item;
                            }
                        })
                        Api.getQuestionById({
                            userId: ls.get('secret_key').userId,
                            questionId: questionId,
                            questionType: 'multipleChoiceQuestions'
                        })
                            .then((response) => {
                                if(response.data.code === 1){
                                    let coktanSecmeliModal = {
                                        questionId: questionId,
                                        multipleChoiceQuestion: response.data.question,
                                        multipleChoiceQuestionOptions: response.data.options,
                                        userAnswer: userAnswer
                                    };
                                    setCoktanSecmeliModal(coktanSecmeliModal)
                                }
                            })
                            .catch((err) => console.log(err.response.request));
                    }
                    }>
                    Bu soruyu çözmüşsünüz!
                </Button>
            )
        }
        else {
            return (
                <Button
                    type="primary"
                    onClick={() => {
                        Api.getQuestionById({
                            userId: ls.get('secret_key').userId,
                            questionId: questionId,
                            questionType: 'multipleChoiceQuestions'
                        })
                            .then((response) => {
                                if(response.data.code === 1){
                                    let coktanSecmeliModal = {
                                        questionId: questionId,
                                        multipleChoiceQuestion: response.data.question,
                                        multipleChoiceQuestionOptions: response.data.options
                                    };
                                    setCoktanSecmeliModal(coktanSecmeliModal)
                                }
                            })
                            .catch((err) => console.log(err.response.request));
                    }
                    }>
                    {index}. Soru İçin Tıkla!
                </Button>
            )
        }
        
    }
    
    const renderCeviriSoruButton = (questionId, index) => {
        let isAnswerExist = userExerciseAnswers && userExerciseAnswers.find(function (item) {
            return parseInt(item.question_type_id) === questionId && item.question_type === 'translate_questions';
        })

        if(isAnswerExist){
            return (
                <Button
                    type="dashed"
                    style={{backgroundColor: "#52c41a", color: 'white'}}
                    onClick={() => {
                        let userAnswer = userExerciseAnswers.find((item) => {
                            if(parseInt(item.question_type_id) === questionId) {
                                return item;
                            }
                        })
                        Api.getQuestionById({
                            userId: ls.get('secret_key').userId,
                            questionId: questionId,
                            questionType: 'translateQuestions'
                        })
                            .then((response) => {
                                if(response.data.code === 1){
                                    let ceviriModal = {
                                        questionId: questionId,
                                        translateQuestion: response.data.question,
                                        translateQuestionOptions: response.data.options,
                                        userAnswer: userAnswer
                                    };
                                    setCeviriModal(ceviriModal)
                                }
                            })
                            .catch((err) => console.log(err.response.request));
                    }
                    }>
                    Bu soruyu çözmüşsünüz!
                </Button>
            )
        }
        else {
            return (
                <Button
                    type="primary"
                    onClick={() => {
                        Api.getQuestionById({
                            userId: ls.get('secret_key').userId,
                            questionId: questionId,
                            questionType: 'translateQuestions'
                        })
                            .then((response) => {
                                if(response.data.code === 1){
                                    let ceviriModal = {
                                        questionId: questionId,
                                        translateQuestion: response.data.question,
                                        translateQuestionOptions: response.data.options
                                    };
                                    setCeviriModal(ceviriModal)
                                }
                            })
                            .catch((err) => console.log(err.response.request));
                    }
                    }>
                    {index}. Soru İçin Tıkla!
                </Button>
            )
        }

    }

    const onFinishExerciseFinish = values => {
        const openFinishSuccessNotificationWithIcon = type => {
            notification[type]({
                message: 'Egzersiz Başarıyla Tamamlandı!',
                description:
                    'Egzersiz sonuçlarınızı ekranda görebilirsiniz!',
            });
        };
        openFinishSuccessNotificationWithIcon('success')
        let fillInTheBlankAnswers = [];
        let dictationAnswers = [];
        let mixedSentenceAnswers = [];
        Object.keys(values).forEach(function(item){
            let anlikItemName = item.split('/')[0];
            let anlikItemId = item.split('/')[1];
            if(values[item] === undefined){
                values[item] = "";
            }
            if(anlikItemName === 'fillInTheBlankQuestion'){
                let pushedData = {
                    questionTypeId: anlikItemId,
                    questionType: 'fill_in_the_blank_questions',
                    userAnswer: values[item]
                        .replace(/I/g, 'i')
                        .replace(/İ/g, 'i')
                        .toLocaleLowerCase()
                        .replace(/  /g, ' ')
                        .replace(/`/g, "'")
                        .replace(/´/g, "'")
                        .replace(/‘/g, "'")
                        .replace(/’/g, "'")
                        .trim()
                        .slice(-1) === '?' 
                    || values[item]
                        .replace(/I/g, 'i')
                        .replace(/İ/g, 'i')
                        .toLocaleLowerCase()
                        .replace(/  /g, ' ')
                        .replace(/`/g, "'")
                        .replace(/´/g, "'")
                        .replace(/‘/g, "'")
                        .replace(/’/g, "'")
                        .trim()
                        .slice(-1) === '!' 
                    || values[item]
                        .replace(/I/g, 'i')
                        .replace(/İ/g, 'i')
                        .toLocaleLowerCase()
                        .replace(/  /g, ' ')
                        .replace(/`/g, "'")
                        .replace(/´/g, "'")
                        .replace(/‘/g, "'")
                        .replace(/’/g, "'")
                        .trim()
                        .slice(-1) === '.' 
                        ? values[item]
                            .replace(/I/g, 'i')
                            .replace(/İ/g, 'i')
                            .toLocaleLowerCase()
                            .replace(/`/g, "'")
                            .replace(/´/g, "'")
                            .replace(/‘/g, "'")
                            .replace(/’/g, "'")
                            .trim()
                            .slice(0,-1) 
                        : values[item]
                            .replace(/I/g, 'i')
                            .replace(/İ/g, 'i')
                            .toLocaleLowerCase()
                            .replace(/  /g, ' ')
                            .replace(/`/g, "'")
                            .replace(/´/g, "'")
                            .replace(/‘/g, "'")
                            .replace(/’/g, "'")
                            .trim()
                }
                fillInTheBlankAnswers.push(pushedData)
            }
            else if(anlikItemName === 'dictationQuestion'){
                let pushedData = {
                    questionTypeId: anlikItemId,
                    questionType: 'dictation_questions',
                    userAnswer: values[item]
                        .replace(/I/g, 'i')
                        .replace(/İ/g, 'i')
                        .toLocaleLowerCase()
                        .replace(/`/g, "'")
                        .replace(/´/g, "'")
                        .replace(/‘/g, "'")
                        .replace(/’/g, "'")
                        .trim()
                        .slice(-1) === '?'
                    || values[item]
                        .replace(/I/g, 'i')
                        .replace(/İ/g, 'i')
                        .toLocaleLowerCase()
                        .replace(/  /g, ' ')
                        .replace(/`/g, "'")
                        .replace(/´/g, "'")
                        .replace(/‘/g, "'")
                        .replace(/’/g, "'")
                        .trim()
                        .slice(-1) === '!'
                    || values[item]
                        .replace(/I/g, 'i')
                        .replace(/İ/g, 'i')
                        .toLocaleLowerCase()
                        .replace(/  /g, ' ')
                        .replace(/`/g, "'")
                        .replace(/´/g, "'")
                        .replace(/‘/g, "'")
                        .replace(/’/g, "'")
                        .trim()
                        .slice(-1) === '.'
                        ? values[item]
                            .replace(/I/g, 'i')
                            .replace(/İ/g, 'i')
                            .toLocaleLowerCase()
                            .replace(/  /g, ' ')
                            .replace(/`/g, "'")
                            .replace(/´/g, "'")
                            .replace(/‘/g, "'")
                            .replace(/’/g, "'")
                            .trim()
                            .slice(0,-1)
                        : values[item]
                            .replace(/I/g, 'i')
                            .replace(/İ/g, 'i')
                            .toLocaleLowerCase()
                            .replace(/  /g, ' ')
                            .replace(/`/g, "'")
                            .replace(/´/g, "'")
                            .replace(/‘/g, "'")
                            .replace(/’/g, "'")
                            .trim()
                }
                dictationAnswers.push(pushedData)
            }
            else if(anlikItemName === 'mixedSentenceQuestion'){
                let pushedData = {
                    questionTypeId: anlikItemId,
                    questionType: 'mixed_sentence_questions',
                    userAnswer: values[item]
                        .replace(/I/g, 'i')
                        .replace(/İ/g, 'i')
                        .toLocaleLowerCase()
                        .replace(/  /g, ' ')
                        .replace(/`/g, "'")
                        .replace(/´/g, "'")
                        .replace(/‘/g, "'")
                        .replace(/’/g, "'")
                        .trim()
                        .slice(-1) === '?'
                    || values[item]
                        .replace(/I/g, 'i')
                        .replace(/İ/g, 'i')
                        .toLocaleLowerCase()
                        .replace(/  /g, ' ')
                        .replace(/`/g, "'")
                        .replace(/´/g, "'")
                        .replace(/‘/g, "'")
                        .replace(/’/g, "'")
                        .trim()
                        .slice(-1) === '!'
                    || values[item]
                        .replace(/I/g, 'i')
                        .replace(/İ/g, 'i')
                        .toLocaleLowerCase()
                        .replace(/  /g, ' ')
                        .replace(/`/g, "'")
                        .replace(/´/g, "'")
                        .replace(/‘/g, "'")
                        .replace(/’/g, "'")
                        .trim()
                        .slice(-1) === '.'
                        ? values[item]
                            .replace(/I/g, 'i')
                            .replace(/İ/g, 'i')
                            .toLocaleLowerCase()
                            .replace(/  /g, ' ')
                            .replace(/`/g, "'")
                            .replace(/´/g, "'")
                            .replace(/‘/g, "'")
                            .replace(/’/g, "'")
                            .trim()
                            .slice(0,-1)
                        : values[item]
                            .replace(/I/g, 'i')
                            .replace(/İ/g, 'i')
                            .toLocaleLowerCase()
                            .replace(/  /g, ' ')
                            .replace(/`/g, "'")
                            .replace(/´/g, "'")
                            .replace(/‘/g, "'")
                            .replace(/’/g, "'")
                            .trim()
                }
                mixedSentenceAnswers.push(pushedData)
            }
        });
        let sendData = {
            dictationAnswers: dictationAnswers,
            mixedSentenceAnswers: mixedSentenceAnswers,
            fillInTheBlankAnswers: fillInTheBlankAnswers,
            userId: ls.get('secret_key').userId,
            exerciseSlug: props.exerciseSlug,
            exerciseCount: props.exerciseCount
        }
        Api.saveAnswerToUserExerciseAnswers({
            questionType: 'allTypes',
            sendData: sendData
        }).then((response) => {
            if(response.data.code === 1){
                openFinishSuccessNotificationWithIcon('success')
                props.isDone(true);
            }
        }).catch((err) => console.log( err.response.request ));
    }

    const onFinishFailedExerciseFinish = err => {
        console.log('bitirilirken hata olustu', err)
    }
    
    return(
        <>
            <Form
                form={form}
                colon={props.fillInTheBlankQuestions.length === 0}
                name="exerciseFinishForm"
                onFinish={onFinishExerciseFinish}
                onFinishFailed={onFinishFailedExerciseFinish}
            >
            <Steps progressDot 
                   direction={useCheckMobileScreen() === true ? 'vertical' : 'horizontal'} 
                   current={1}>
                <Step
                    title="Başlayın!"
                    description={<Button type="primary" disabled>Şimdi Başla!</Button>}
                />
                <Step
                    title="Başladınız..."
                    description="Lütfen soruları çözünüz."
                />
                <Step
                    title="Bitirin!"
                    description={
                        <Form.Item>
                            <Button
                                type="danger"
                                htmlType="submit"
                            >
                                Şimdi Bitir!
                            </Button>
                        </Form.Item>
                    }
                />
            </Steps>
            <Divider />
                {activeSteps(props)}
            </Form>
            <CoktanSecmeliModal
                coktanSecmeliModal={coktanSecmeliModal}
                visibleState={(isReload) => {
                    isReload && loadProgressBarData();
                    setCoktanSecmeliModal(null);
                }}
            />
            <CeviriModal
                ceviriModal={ceviriModal}
                visibleState={(isReload) => {
                    isReload && loadProgressBarData();
                    setCeviriModal(null);
                }}
            />
            <CrosswordModal
                crosswordModal={crosswordModal}
                visibleState={(isReload) => {
                    isReload && loadProgressBarData();
                    setCrosswordModal(null);
                }}
            />
        </>
    )
}

export default StartedProgressBar;