import React from "react";
import {Modal, notification} from 'antd';
import 'antd/dist/antd.css';
import SecureLS from "secure-ls";
import Api from "../common/Api";

const CrosswordModal = (props) => {
    const ls = new SecureLS({encodingType: 'aes'});
    const {crosswordModal, visibleState} = props

    const createCrossword = () => {
        if(crosswordModal !== undefined && crosswordModal !== null){
            return {__html: crosswordModal.crosswordArray}
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        let crosswordData = []
        event.persist();
        for (const input in event.target) {
            if(parseInt(input) === 0){
                continue;
            }
            let anlikName = event.target[parseInt(input)].name;
            let rowArrayNotSplited = anlikName.split('[')[1]
            let row = rowArrayNotSplited.split(']')[0]
            let colArrayNotSplited = anlikName.split('[')[2]
            let col = colArrayNotSplited.split(']')[0]
            let anlikValue = event.target[parseInt(input)].value.toLowerCase();
            if((event.target.length - 1) === parseInt(input)){
                let addData = {
                    row: parseInt(row),
                    col: parseInt(col),
                    value: anlikValue.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
                }
                crosswordData.push(addData)
                break;
            }
            let addData = {
                row: parseInt(row),
                col: parseInt(col),
                value: anlikValue.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
            }
            crosswordData.push(addData)
        }
        let isTrue = 1;
        
        crosswordData.every((data) => {
            if(crosswordModal.crosswordTrueArray[data.row][data.col] !== data.value){
                isTrue = 0
                return false;
            }else {
                return true
            }
        })
        let sendData = {
            isCrosswordTrue: isTrue,
            trueCrosswordData: crosswordModal.crosswordTrueArray,
            trueCrosswordHTML: crosswordModal.crosswordArrayDolu,
            userCrosswordData: crosswordData,
            emptyCrosswordData: crosswordModal.crosswordArray,
            questionTypeId: crosswordModal.exerciseId
        }
        const openFinishSuccessNotificationWithIcon = type => {
            notification[type]({
                message: 'Bulmaca Cevaplarınız Başarıyla Kaydedildi!',
                description:
                    'Egzersize devam edebilirsiniz!',
            });
        };
        Api.saveAnswerToUserExerciseAnswers({
            questionType: 'crossword_questions',
            sendData: sendData,
            userId: ls.get('secret_key').userId
        }).then((response) => {
            if(response.data.code === 1){
                openFinishSuccessNotificationWithIcon('success')
                props.visibleState(true);
            }
        }).catch((err) => console.log( err.response.request ));
    }
    return(
        <>
            <Modal
                title="Bulmacayı altındaki sorulara göre çözünüz."
                visible={!!crosswordModal}
                // onOk={onFinish}
                width={800}
                style={{textAlign: "center"}}
                onCancel={props.visibleState}
                footer={null}
            >
                <form onSubmit={handleSubmit}>
                    <button id="hayat" style={{background: 'green', color:'white', width: '200px', height: '70px'}}>Cevapları Kaydet!</button>
                    <div dangerouslySetInnerHTML={createCrossword()}/>
                </form>
            </Modal>
        </>
    )
}

export default CrosswordModal;